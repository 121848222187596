import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import Image from "@ui/image";
import Anchor from "@ui/anchor";

import { LogosWrapper, LogosBoxWrap, LogoBox } from "./style";

const MicrositeLogo = ({ data }) => {
  return (
    <LogosWrapper>
      <Container>
        <Row>
          <Col>
            <LogosBoxWrap>
              {data.images.map((data , index) => (
                <LogoBox key={index}>
                  <Anchor path={data.alt} target="_blank">
                    <Image src={data.src} alt={"logo"} />
                  </Anchor>
                </LogoBox>
              ))}
            </LogosBoxWrap>
          </Col>
        </Row>
      </Container>
    </LogosWrapper>
  );
};
export default MicrositeLogo;
