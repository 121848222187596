import styled from "@styled";

export const LogosWrapper = styled.section`
  padding: 0 0 60px;
`

export const LogosBoxWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`
export const LogoBox = styled.div`
  padding: 0 10px;
  flex: 1 1 auto;
`