import React from "react";
import styled, { themeGet, device, color } from "@styled";

export const SectionWrap = styled(({ bg, ...rest }) => <section {...rest} />)`
  padding-block-start: 60px;
  padding-block-end: 25px;
  background: #f8f8f8;
  ${device.medium} {
    padding-block-start: 80px;
    padding-block-end: 45px;
  }
  ${device.large} {
    padding-block-start: 120px;
    padding-block-end: 105px;
  }
  ${color};
`;

export const HeaderWrap = styled.div`
  margin-block-end: 20px;
  ${device.medium} {
    margin-inline-end: 3rem;
    margin-block-end: 0;
  }
`;

export const StyledLeftHeading = styled.h4`
  font-size: 24px;
  span {
    color: ${themeGet("colors.primary")};
  }
  ${device.medium} {
    font-size: 28px;
  }
  ${device.large} {
    font-size: 38px;
  }
`;
export const CounterBox = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 15px;
  ${device.small} {
    grid-template-columns: 1fr 1fr;
  }
  ${device.large} {
    grid-template-columns: 260px 260px;
  }
  ${device.xlarge} {
    grid-gap: 25px;
  }
`;
